.bg-1908{
    background-color: #940045;
}
@media (min-width: 576px){
    div.col-menu-1908{
        width: 185px;
    }
}

@media (max-width: 575px){
    img#logo-menu{
        display: none;
    }
}

div.col-menu-1908 a{
    color: #e7e7e7;
}
div.col-menu-1908 a:hover{
    color: gray;
}

img#logo-menu{
    max-width: 100%;
}