a{
  text-decoration: none;
}

body{
  /*background: #f8f8f8;*/
  background: #e9e9e9;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

span.micro{
  font-size: 9px;
  color: gray;
}

table.table-pointer tr {
  cursor: pointer;
}

.card table td, .card table th{
  padding: 6px;
}

.breadcrumb-1{
  margin-bottom: 10px;
}

/*Tabelle flex usata nella simulazione apps.noberasco.it */

.flx-items-table {
  background: #d7d7d7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
}

.flx-items-table-no-details{
  border-radius: 10px;
}

.flx-items-table-details{
  background: #f1f1f1;
  margin-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
}
.flx-items-table-details-toggle{
  cursor: pointer;
  font-weight: bold;
}
.flx-items-table-details-toggle:hover{
  color: #66bb6a;
}

/* ************************ */
